import React from 'react'
import { Grid } from '@material-ui/core';
import Logo_Fb from '../../images/Logo_Fb.png';
import Logo_Insta from '../../images/Logo_Insta.png';
import Logo_Mustang from '../../images/logochezsidi1.png';
import './Footer.css';
import { useLocation } from "react-router-dom";
import CGV from "../../Documents/cgvchezsidi.pdf";
import ML from "../../Documents/mlchezsidi.pdf";


const Footer = () => {
  const location = useLocation();

  const regex = /^\/admin/g;
  //   ne pas afficher dans la page admin
  if (!location.pathname.match(regex)) {
    return (
      <div >
            <Grid container id="footer">
              <Grid container className="footer-logo-border" justifyContent="center" xs={12} md={2}>
                <a href="/#header">
                  <img src={Logo_Mustang} alt="logo Mustang Footer"></img>
                </a>
              </Grid>
              <Grid container className="footer-text" direction="row" xs={12} md={10}>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>ADRESSE</h5>
                    <p>
                      <a 
                        className="footer-link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.google.https://www.google.com/maps/place/Chez+Sidi/@48.7753706,1.9978724,15z/data=!4m2!3m1!1s0x0:0x6e8b2fdbb3f367a1?sa=X&ved=2ahUKEwjumr_By7z8AhUhUKQEHYHKDWQQ_BJ6BAg-EAM/maps/place/Royal+Delice/@48.7676753,2.0176659,3a,75y,344.93h,91.88t/data=!3m6!1e1!3m4!1sR2qUtbvDtzJOLd29RVd6Hw!2e0!7i16384!8i8192!4m13!1m7!3m6!1s0x47e6814472a05af5:0x2a9cdea442016961!2s3+Rue+Jean+Goujon,+78180+Montigny-le-Bretonneux!3b1!8m2!3d48.7678374!4d2.0176193!3m4!1s0x47e6814472d0df0b:0xeaa22617e04f17e5!8m2!3d48.7677907!4d2.017703"                    
                      >
                        Chez Sidi <br/> Rue Jean Jaurès<br/> 78190 TRAPPES
                      </a>
                    </p>
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>HORAIRES</h5>
                    <p>Mardi au Dimanche : 19h00 - 23h00</p>
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>CONTACT</h5>
                    <p>
                    <a class="footer-link" href="tel:06 15 24 40 88"> 06 15 24 40 88</a>
                       <br></br> 
                    <a 
                      className="footer-link" 
                      href="mailto:chezsidi.manager@gmail.com"
                      rel="noreferrer"
                    > chezsidi.manager@gmail.com</a>
                    </p>
                  </Grid>
                  <Grid xs={12} sm={6} md={3}>
                    <h5>REJOIGNEZ-NOUS !</h5>
                    <p>
                      <a href="/" target="_blank" rel="noreferrer" >
                      <i className="fab fa-facebook-f"/>
                      </a>
                       &nbsp; 
                      <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" >
                      <i className="fab fa-instagram"/>
                      </a>
                    </p>
                  </Grid>

                  

              </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{backgroundColor:'#261407'}}>
                
                    <span className="footer-copyright">Copyright 2021 ©  |  Made by <a 
                      className="footer-link" 
                      target="_blank" 
                      rel="noreferrer" 
                      href="http://www.ceostech.fr/"
                    > Ceos Tech </a>  |  <a className="footer-link" href={CGV} target="_blank">CGV</a>  |  <a className="footer-link" href={ML} target="_blank">Mentions Légales </a></span>
                
            </Grid>
        </div>
  
    ) }

    return null;
  };
  export default Footer;